import React from 'react';
import { Link } from 'gatsby';

import './NotFound.scss';

function NotFound({ notFound, tag }) {
  return (
    <div className="not-found">
      <div className="container not-found__container">
        <h1 className="not-found__title">
          404
        </h1>
        <h2 className="not-found__description">
          {notFound.not_found_title_text}
        </h2>
        <h3 className="not-found__subtitle">
          {notFound.not_found_subtitle_text}
        </h3>
        <Link to={`/${tag}/`} className="not-found__link button button--red">
          {notFound.back_to_home_page_button}
        </Link>
      </div>
    </div>
  );
}

export default React.memo(NotFound);
