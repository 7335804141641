import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import NotFound from 'components/NotFound';

export default function PageNotFound({ pageContext: { notFound, links, navigation, tag } }) {
  return (
    <>
    <Helmet>
      <meta property='og:image' content='/seo/preview.jpg' />
      <meta property='og:image:width' content={820} />
      <meta property='og:image:height' content={540} />
    </Helmet>
    <Layout
      isHeaderTransparent
      links={links}
      navigation={navigation}
      tag={tag}
      metaTitle='Not Found | Reach Blockchain Development Platform'
    >
      <NotFound notFound={notFound} tag={tag} />
    </Layout>
    </>
  );
}
